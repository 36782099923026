<template>
    <main>
        <div class="card">
            <div class="card-body">
                <form class="application-form" :class="Object.getOwnPropertyNames(formErrors).length && 'was-validated'" ref="applicationForm">

                    <!-- reCaptcha failure as a callout -->
                    <callout :message="renderErrors(formErrors.recaptchaToken)" v-if="formErrors.recaptchaToken"></callout>

                    <div class="form-section">
                        <h3>Personal details</h3>
                        <div class="form-row" v-if="inviteIdRequired">
                            <div class="form-group col-md-12">
                                <label for="inviteid" class="required">Invitation ID</label>
                                <p class="text-muted" v-if="form.inviteId === null && !hasInviteIdFromURL">
                                    If you have an invitation ID, you should enter it here. If you do not have one, you will need to contact
                                    your local unit and speak to them first before completing this form.
                                </p>
                                <input id="inviteid" name="Invite ID" class="form-control" required="required"
                                       v-model="form.inviteId"
                                       v-bind:disabled="form.inviteId !== null && hasInviteIdFromURL"
                                       v-bind:placeholder="placeholderText">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <label for="unitType" class="required">Unit type</label>
                                <select id="unitType" class="form-control" required="required" v-model="localUnitType">
                                    <option value="" disabled selected hidden>Please select a unit type...</option>
                                    <option v-for="entry in lookupUnitTypes" :value="entry.unitType">{{ entry.label }}</option>
                                </select>
                                <p class="invalid-feedback" v-if="formErrors.unit">{{ renderErrors(formErrors.unit) }}</p>
                            </div>
                            <div class="form-group col-md-4">
                                <label for="unit" class="required">{{ unitLabel }}</label>
                                <select id="unit" class="form-control" required="required" v-model.number="form.unit" :disabled="localUnitType === ''">
                                    <option value="" disabled selected hidden>Please select a unit...</option>
                                    <option v-for="unit in lookupUnitsByType(localUnitType)" :value="unit.id">{{ unit.unitName }}</option>
                                </select>
                                <p class="invalid-feedback" v-if="formErrors.unit">{{ renderErrors(formErrors.unit) }}</p>
                            </div>
                            <div class="form-group col-md-4">
                                <label for="role" class="required">Role</label>
                                <select id="role" class="form-control" required="required" v-model="form.role">
                                    <option value="" disabled selected hidden>Please select a role from the list...</option>
                                    <option v-for="role in lookupRole" :value="role.id">{{ role.label }}</option>
                                </select>
                                <p class="invalid-feedback" v-if="formErrors.role">{{ renderErrors(formErrors.role) }}</p>
                            </div>
                        </div>

                    </div>

                    <div class="form-section">
                        <h3>Personal details</h3>
                        <div class="form-row">
                            <div class="form-group col-md-2">
                                <label for="title" class="required">Title</label>
                                <select id="title" class="form-control" required="required" v-model="form.title">
                                    <option value="">Select...</option>
                                    <option v-for="title in lookupTitle" :value="title.id">{{ title.title }}</option>
                                </select>
                                <p class="invalid-feedback" v-if="formErrors.title">{{ renderErrors(formErrors.title) }}</p>
                            </div>
                            <div class="form-group col-md-5">
                                <label for="forename" class="required">First name</label>
                                <input type="text" class="form-control" id="forename"
                                       required="required"
                                       v-model="form.forename">
                                <p class="invalid-feedback" v-if="formErrors.forename">{{ renderErrors(formErrors.forename) }}</p>
                            </div>
                            <div class="form-group col-md-5">
                                <label for="surname" class="required">Surname</label>
                                <input type="text" class="form-control" id="surname" required="required" v-model="form.surname">
                                <p class="invalid-feedback" v-if="formErrors.surname">{{ renderErrors(formErrors.surname) }}</p>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="knownAs">Known As</label>
                                <input type="text" class="form-control" id="knownAs" v-model="form.knownAs">
                                <p class="mt-1 text-muted small">"Known as" allows you to choose a different first name to that of your legal name. It is
                                used in your display name and will form the first part of your username.</p>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="dateofbirth" class="required">Date of Birth</label>
                                <input type="date" id="dateofbirth" class="form-control"
                                       required="required"
                                       v-model="form.dateOfBirth">
                                <p class="invalid-feedback" v-if="formErrors.dateOfBirth">{{ renderErrors(formErrors.dateOfBirth) }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="form-section">
                        <h3>Personal details</h3>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="gender" class="required">Gender</label>
                                <select id="gender" class="form-control" required="required" v-model="form.gender">
                                    <option value="" disabled selected hidden>Please choose from the list...</option>
                                    <option v-for="gender in lookupGender" :value="gender.id">{{ gender.gender }}</option>
                                </select>
                                <p class="invalid-feedback" v-if="formErrors.gender">{{ renderErrors(formErrors.gender) }}</p>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="pronoun" class="required">Pronouns</label>
                                <select id="pronoun" class="form-control" required="required" v-model="form.pronoun">
                                    <option value="" disabled selected hidden>Please choose from the list...</option>
                                    <option v-for="pronoun in lookupPronoun" :value="pronoun.id">{{ pronoun.pronoun }}</option>
                                </select>
                                <p class="invalid-feedback" v-if="formErrors.pronoun">{{ renderErrors(formErrors.pronoun) }}</p>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <label for="ethnicity" class="required">Ethnicity</label>
                                <select id="ethnicity" class="form-control" required="required" v-model="form.ethnicity">
                                    <option value="" disabled selected hidden>Please choose from the list...</option>
                                    <optgroup v-for="obj in lookupEthnicity" :label="obj.group">
                                        <option v-for="ethnicity in obj.entries" :value="ethnicity.id">{{ ethnicity.ethnicity }}</option>
                                    </optgroup>
                                </select>
                                <p class="invalid-feedback" v-if="formErrors.ethnicity">{{ renderErrors(formErrors.ethnicity) }}</p>
                            </div>
                            <div class="form-group col-md-4">
                                <label for="religion" class="required">Religion</label>
                                <select id="religion" class="form-control" required="required" v-model="form.religion">
                                    <option value="" disabled selected hidden>Please choose from the list...</option>

                                    <template v-for="obj in lookupReligion">
                                        <option v-if="!obj.group" v-for="religion in obj.entries" :value="religion.id">{{ religion.religion }}</option>
                                        <optgroup v-if="obj.group" :label="obj.group">
                                            <option v-for="religion in obj.entries" :value="religion.id">{{ religion.religion }}</option>
                                        </optgroup>
                                    </template>
                                </select>
                                <p class="invalid-feedback" v-if="formErrors.religion">{{ renderErrors(formErrors.religion) }}</p>
                            </div>
                            <div class="form-group col-md-4">
                                <label for="nationality" class="required">Nationality</label>
                                <input type="text" class="form-control" id="nationality" name="nationality"
                                       required="required"
                                       v-model="form.nationality">
                                <p class="invalid-feedback" v-if="formErrors.nationality">{{ renderErrors(formErrors.nationality) }}</p>
                            </div>
                        </div>

                    </div>

                    <div class="form-section">
                        <h3>Personal Details</h3>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="employer">Employer</label>
                                <input type="text" class="form-control" id="employer" v-model="form.employer">
                            </div>
                            <div class="form-group col-md-6">
                                <label for="profession" class="required">Profession</label>
                                <select id="profession" class="form-control" required="required" v-model="form.profession">
                                    <option value="" disabled selected hidden>Please choose from the list...</option>
                                    <option v-for="profession in lookupProfession" :value="profession.id">{{ profession.profession }}</option>
                                </select>
                                <p class="invalid-feedback" v-if="formErrors.profession">{{ renderErrors(formErrors.profession) }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="form-section">
                        <h3>Contact details</h3>
                        <div class="form-row">
                            <p>
                                Please provide your contact details using the form below. Following your initial
                                application you will receive a series of welcome emails that provide access to
                                additional Air Cadet systems which will allow you to complete your application process.
                            </p>
                            <div class="form-group col-md-4">
                                <label for="property" class="required">Property Name or Number</label>
                                <input type="text" class="form-control" id="property" name="property"
                                       required="required"
                                       v-model="form.property">
                                <p class="invalid-feedback" v-if="formErrors.property">{{ renderErrors(formErrors.property) }}</p>
                            </div>
                            <div class="form-group col-md-4">
                                <label for="street" class="required">Street Name</label>
                                <input type="text" class="form-control" id="street" required="required" v-model="form.street">
                                <p class="invalid-feedback" v-if="formErrors.street">{{ renderErrors(formErrors.street) }}</p>
                            </div>
                            <div class="form-group col-md-4">
                                <label for="town" class="required">Town</label>
                                <input type="text" class="form-control" id="town" required="required" v-model="form.town">
                                <p class="invalid-feedback" v-if="formErrors.town">{{ renderErrors(formErrors.town) }}</p>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <label for="county" class="required">County</label>
                                <input type="text" class="form-control" id="county" required="required" v-model="form.county">
                                <p class="invalid-feedback" v-if="formErrors.county">{{ renderErrors(formErrors.county) }}</p>
                            </div>
                            <div class="form-group col-md-4">
                                <label for="postcode" class="required">Postcode</label>
                                <input type="text" class="form-control" id="postcode" name="postcode"
                                       required="required"
                                       v-model="form.postcode">
                                <p class="invalid-feedback" v-if="formErrors.postcode">{{ renderErrors(formErrors.postcode) }}</p>
                            </div>
                            <div class="form-group col-md-4">
                                <label for="countrycode" class="required">Country</label>
                                <select id="countrycode" class="form-control" required="required" v-model="form.countryCode">
                                    <option value="">Please choose from the list...</option>
                                    <option v-for="country in lookupCountry" :value="country.countryCode">{{ country.country }}</option>
                                </select>
                                <p class="invalid-feedback" v-if="formErrors.countryCode">{{ renderErrors(formErrors.countryCode) }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="form-section">
                        <h3>Contact Details</h3>
                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <label for="homephone">Home Phone</label>
                                <input type="text" class="form-control" id="homephone" v-model="form.homePhone">
                            </div>
                            <div class="form-group col-md-4">
                                <label for="mobilephone" class="required">Mobile Phone</label>
                                <input type="text" class="form-control" id="mobilephone" name="mobilephone"
                                       required="required"
                                       v-model="form.mobilePhone">
                                <p class="invalid-feedback" v-if="formErrors.mobilePhone">{{ renderErrors(formErrors.mobilePhone) }}</p>
                            </div>
                            <div class="form-group col-md-4">
                                <label for="email" class="required">Email</label>
                                <input type="email" class="form-control" id="email" required="required" v-model="form.email">
                                <p class="invalid-feedback" v-if="formErrors.email">{{ renderErrors(formErrors.email) }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="form-section">
                        <div class="form-row">
                            <h3>Agreement</h3>
                            <p>
                                The role that you have applied for is a position whose normal duties include caring for,
                                training, supervising or being in sole charge of children (a ‘regulated position’ in
                                accordance with the Rehabilitation of Offenders Act 1974 (Exceptions) Order 1975, the
                                Exclusions and Exceptions (Scotland) Order 2003, the Police Act 1997, the Safeguarding
                                of Vulnerable Groups Act 2006 and the Protection of Vulnerable Groups (Scotland) Act
                                2007). By signing the below, I understand, agree and accept that if I am appointed as a
                                volunteer in the RAF Air Cadets, I must meet and adhere to the following declaration:
                            </p>
                            <ol type="a">
                                <li>I hereby declare that I have not been disqualified from working with children or
                                    subject to a Disqualification Order within the meaning of the Criminal Justice and Court
                                    Services Act 2000.</li>
                                <li>I understand that this appointment is conditional upon the receipt of a satisfactory
                                    enhanced criminal records check. I also give my consent for the RAFAC to make
                                    appropriate enquiries to any other relevant authority.</li>
                                <li>I hereby give formal written consent, under the provisions of the Data Protection Act
                                    1998/GDPR 2018, for personal data held by the RAF or MOD to be disclosed to HQ RAF Air
                                    Cadets for the benefit of this application. I understand that personal information held
                                    by the RAFAC may also be shared with the relevant MOD or RAF authorities, including
                                    other Cadet Forces.</li>
                                <li>I agree to inform the RAFAC if a criminal charge is to be preferred against me or if
                                    I am convicted of any criminal offence (excluding minor road traffic offences), become
                                    the subject of a police, social services, MOD/RAF or professional body child protection
                                    related investigation after I am appointed. I understand that failure to do so may lead
                                    to the immediate suspension of my appointment in the RAFAC and/or termination of RAFAC
                                    service in accordance with current RAFAC regulations.</li>
                                <li>I agree to abide by Air Cadet Publication (ACP) 1 (Ethos, Core Values and Standards
                                    of the RAF Air Cadets and ACP 4 (Safeguarding and Protecting Children) and I understand
                                    that personal relationships between adult members of staff and cadets of any age are
                                    strictly forbidden.</li>
                                <li>I certify that the information contained in this form is true and correct to the best
                                    of my knowledge and I realise that false information or wilful omissions may lead to the
                                    immediate suspension or termination of my appointment with the RAFAC.</li>
                            </ol>
                            <div class="form-group">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="gridCheck" name="agreement"
                                           required="required" v-model="form.agreementAccepted">
                                    <label class="form-check-label required" for="gridCheck">
                                        I confirm that I have understood and agree to all statements above.</label>
                                    <p class="invalid-feedback" v-if="formErrors.agreementAccepted">{{ renderErrors(formErrors.agreementAccepted) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-navigation">
                        <button class="btn bg-bader text-white pull-right" v-on:click="submitApplication" v-bind:disabled="submittingApplication">{{ submitButtonText }}</button>
                        <span class="clearfix"></span>
                    </div>

                </form>
            </div>
        </div>
    </main>
</template>

<style scoped>
label.required:after {
    content: " *";
    color: #ff0000;
}
</style>

<script>

import axios from 'axios';
import Swal from 'sweetalert2';
import sortBy from 'lodash/sortBy';
import callout from "../../shared/components/callout";
import { useReCaptcha } from 'vue-recaptcha-v3';

export default {
    setup() {
        const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
        const recaptchaSubmit = function (actionType)  {
            return recaptchaLoaded()
                .then(() => {
                    return executeRecaptcha(actionType);
                });
        }

        return {
            recaptchaSubmit
        };
    },
    components: {
        callout
    },
    props: {
        submitUrl: String,
        completionUrl: String,
        unitsUrl: String,
        genderUrl: String,
        pronounUrl: String,
        ethnicityUrl: String,
        religionUrl: String,
        countryUrl: String,
        professionUrl: String,
        titleUrl: String
    },
    data() {
        return {
            inviteIdRequired: false,

            recaptchaActionType: 'application/submit/cfav',

            // Form data
            form: {
                recaptchaToken: '',
                inviteId: null,
                unit: '',
                role: '',
                forename: '',
                surname: '',
                knownAs: '',
                title: '',
                dateOfBirth: null,
                gender: '',
                pronoun: '',
                ethnicity: '',
                religion: '',
                profession: '',
                nationality: '',
                employer: '',
                property: '',
                street: '',
                town: '',
                county: '',
                postcode: '',
                countryCode: '',
                homePhone: '',
                mobilePhone: '',
                email: '',
                agreementAccepted: false
            },

            formErrors: {},

            // Data lookups
            lookupRole: [
                { id: 275, label: "Civilian Instructor" },
                { id: 276, label: "Civilian Gliding Instructor" },
                { id: 277, label: "Chaplain" },
                { id: 1281, label: "Civilian Committee Member" },
            ],
            sourceUnits: [],
            sourceGender: [],
            sourcePronoun: [],
            sourceEthnicity: [],
            sourceReligion: [],
            sourceCountry: [],
            sourceProfession: [],
            sourceTitle: [],

            // Controls
            submittingApplication: false,
            hasInviteIdFromURL: false,

            // Form mapping
            mapStringToNullFields: [
                'unit', 'role', 'gender', 'ethnicity', 'religion', 'pronoun', 'profession'
            ],

            // Misc
            timerMs: 1500,
            localUnitType: ''
        }
    },
    computed: {
        placeholderText: function () {
            if (this.form.inviteId === null)
                return 'Please enter your invitation ID';

            return '';
        },
        submitButtonText: function () {
            return this.submittingApplication ? 'Please wait, submitting...' : 'Submit your application';
        },

        // Lookup mapping - interject here if you need to do eg sorting
        lookupUnitTypes() {
            let unitTypes = [];
            let self = this;
            this.sourceUnits.map(function (unit) {
                if (unitTypes.indexOf(unit.unitType) === -1) {
                    unitTypes.push(unit.unitType);
                }

                return unit;
            });

            // return a nice object to iterate over
            let unitTypesObjs = unitTypes.map(function (unitType) {
                return {
                    unitType: unitType,
                    label: self.unitLabelByType(unitType)
                }
            });
            return sortBy(unitTypesObjs, ['label']);
        },
        lookupUnits() { return sortBy(this.sourceUnits, ['unitName']); },
        lookupGender() { return this.sourceGender; },
        lookupPronoun() { return this.sourcePronoun; },
        lookupEthnicity() { return this.sourceEthnicity; },
        lookupReligion() { return this.sourceReligion; },
        lookupCountry() { return this.sourceCountry; },
        lookupProfession() { return this.sourceProfession; },
        lookupTitle() { return this.sourceTitle; },

        // Misc
        unitLabel() {
            if (this.localUnitType === '') {
                return 'Unit';
            }

            return 'Unit (' + this.unitLabelByType(this.localUnitType) + ')';
        }
    },
    methods: {
        renderErrors: function(value) {
            return Array.isArray(value) ? value.join('<br>') : value;
        },
        lookupUnitsByType(unitType) {
            return this.lookupUnits.filter(unit => unit.unitType.toLowerCase() === unitType.toLowerCase());
        },
        unitLabelByType(unitType) {
            switch (unitType.toLowerCase()) {
                case 'sqn':
                    return 'Squadron';
                case 'ccf':
                    return 'CCF School';
                case 'twofts':
                    return '2 FTS';
                case 'vgs':
                    return 'VGS';
                case 'whq':
                    return 'Wing';
                default:
                    return '';
            }
        },
        async submitApplication () {
            if (!this.submitUrl) {
                throw new Error('Submit URL not defined');
            }

            this.submittingApplication = true;
            this.form.recaptchaToken = await this.recaptchaSubmit(this.recaptchaActionType)

            let data = Object.assign({}, this.form);
            this.mapStringToNullFields.forEach((field) => {
                data[field] = data[field] === '' ? null : data[field];
            });

            let self = this;
            Swal.fire({
                title: 'Please wait...',
                showConfirmButton: false
            });
            axios.post(this.submitUrl, data)
                .then(response => {
                    // Submission successful
                    Swal.fire({
                        icon: 'success',
                        title: '',
                        text: 'Your application has been submitted',
                        showConfirmButton: false,
                        timer: this.timerMs
                    }).then(function() {
                        window.location = self.completionUrl;
                    });
                })
                .catch(error => {
                    if (error.response) {
                        // Use Object.assign so that Vue watches the new properties
                        this.formErrors = Object.assign({}, this.formErrors, error.response.data);
                    }
                    Swal.fire({
                        icon: 'error',
                        title: '',
                        text: 'There was a problem submitting your application',
                        showConfirmButton: false,
                        timer: this.timerMs
                    }).then(() => {
                        window.scroll({
                            top: 0,
                            left: 0,
                            behavior: 'smooth'
                        });
                    });
                    this.submittingApplication = false;
                    this.form.recaptchaToken = '';
                });
        }
    },
    mounted() {
        // Get invitation ID from URL, if it's there and if we have it enabled
        if (this.inviteIdRequired) {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const urlInviteId = urlParams.get('inviteid');
            this.form.inviteId = urlInviteId || null;
            this.hasInviteIdFromURL = this.form.inviteId?.length > 0;
        }

        // Load data sources
        // Note that we return null from the catch to avoid one request failing everything else
        const unitsRequest = axios.get(this.unitsUrl).catch(err => null);
        const genderRequest = axios.get(this.genderUrl).catch(err => null);
        const pronounRequest = axios.get(this.pronounUrl).catch(err => null);
        const ethnicityRequest = axios.get(this.ethnicityUrl).catch(err => null);
        const religionRequest = axios.get(this.religionUrl).catch(err => null);
        const countryRequest = axios.get(this.countryUrl).catch(err => null);
        const professionRequest = axios.get(this.professionUrl).catch(err => null);
        const titleRequest = axios.get(this.titleUrl).catch(err => null);
        Promise.all([unitsRequest, genderRequest, pronounRequest, ethnicityRequest, religionRequest, countryRequest, professionRequest, titleRequest])
            .then((responses) => {
                this.sourceUnits = responses[0] && responses[0].data;
                this.sourceGender = responses[1] && responses[1].data;
                this.sourcePronoun = responses[2] && responses[2].data;
                this.sourceEthnicity = responses[3] && responses[3].data;
                this.sourceReligion = responses[4] && responses[4].data;
                this.sourceCountry = responses[5] && responses[5].data;
                this.sourceProfession = responses[6] && responses[6].data;
                this.sourceTitle = responses[7] && responses[7].data;
            });
    }
};
</script>
