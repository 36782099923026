import { createApp } from 'vue/dist/vue.esm-bundler';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import recaptchaConfig from '../config/recaptcha';

export function CreateIfExistsVueApp(component, selector) {
    if (!document.querySelector(selector))
        return;

    createApp(component)
        // Global recaptcha config for ease
        .use(VueReCaptcha, { siteKey: recaptchaConfig.siteKey })
        .mount(selector);
}
